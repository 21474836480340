import { Container } from "components/ui/objects/container";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

import { Details, Europe, Flex, Wrapper } from "./styles";

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          foundingDate
          legalName
          phone
          email
        }
      }
      allPcisJson {
        nodes {
          name
          slug
        }
      }
      allFile(filter: { sourceInstanceName: { eq: "docs-generales" } }) {
        nodes {
          name
          relativePath
          extension
        }
      }
    }
  `);
  function normalizeTextWithoutHyphen(text) {
    return text.replace(/[-|_]/g, " ").toLowerCase();
  }
  return (
    <Wrapper>
      <Europe as={Container}>
        <Details>
          ORDEN ECD/946/2016, de 4 de agosto, por la que se regulan los Programas de Cualificación Inicial de Formación
          Profesional dirigidos a personas sin cualificación profesional o con necesidades educativas especiales en la
          Comunidad Autónoma de Aragón.
        </Details>
      </Europe>
      <Flex as={Container}>
        <Details>
          <p>
            <b>Información legal</b>
          </p>
          <ul>
            <li>
              <a href="/legal/aviso-legal/" target="_blank" rel="nofollow noopener noreferrer">
                Aviso legal
              </a>
            </li>
            <li>
              <a href="/legal/politica-de-privacidad/" target="_blank" rel="nofollow noopener noreferrer">
                Política de privacidad
              </a>
            </li>
            <li>
              <a href="/legal/politica-de-cookies/" target="_blank" rel="nofollow noopener noreferrer">
                Política de cookies
              </a>
            </li>
            <li>
              <button type="button" data-cc="show-preferencesModal">
                Configuración cookies
              </button>
            </li>
          </ul>
        </Details>
        <Details>
          <p>
            <b>Enlaces </b>
          </p>
          <ul>
            <li><a href="https://iesryczaragoza.catedu.es/" target={"_blank"} rel={"noopener"}> Web del IES Ramón y Cajal</a> </li>
            {data.allFile.nodes.forEach((document, index) => {
                  if (document.name === '.keepgit'
                )
                  {
                    return
                  }
                  return (
                      <li key={index}>
                        <a
                            href={`/downloads/generales/${document.relativePath}`}
                            title={`Descargar ${document.relativePath}`}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                        >
                          {normalizeTextWithoutHyphen(document.name)}
                        </a>
                      </li>
                  )
                }
            )}
          </ul>
        </Details>
        <Details>
          <p>
            <b>Información del centro</b>
          </p>
          <p>{data.site.siteMetadata.legalName}</p>
          <p>
            C/ Ramón Pignatelli, 102, <br />
            50004 Zaragoza
          </p>
          Teléfono:{" "}
          <a href={`tel:${data.site.siteMetadata.phone}`} target="_blank" rel="nofollow noopener noreferrer">
            {data.site.siteMetadata.phone}
          </a>
          <br />
          Email:{" "}
          <a href={`mailto:${data.site.siteMetadata.email}`} target="_blank" rel="nofollow noopener noreferrer">
            {data.site.siteMetadata.email}
          </a>
        </Details>
        <Details>
          <p>
            <b>Accesos</b>
          </p>
          <ul>
            {data.allPcisJson.nodes.map((pci) => (
              <li key={pci.slug}>
                <Link to={`/${pci.slug}/`}>{pci.name}</Link>
              </li>
            ))}
            <li>
              <Link to={"/inserta-xxi/"}>Inserta XXI</Link>
            </li>
          </ul>
        </Details>
      </Flex>
    </Wrapper>
  );
};
