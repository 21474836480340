// import logo from "assets/images/logo-jornadas-small.png";
import { Container } from "components/ui/objects/container";
import { Link } from "gatsby";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import NavbarLinks from "../navbarLinks";
import { Wrapper } from "./styles";

const Navbar = () => {
  return (
    <Wrapper as={Container}>
      {/*<Link to="/" ><img src={logo} alt="Inserta XXI" width="63" height="63"/></Link>*/}
      <Link to="/" className={"h1"} style={{ width: "fit-content" }}>
        <div className="square-100"></div>
        PCI
      </Link>
      <NavbarLinks desktop />
    </Wrapper>
  );
};

export default Navbar;
