import styled from "styled-components";
import theme from "styles/style";

export const Container = styled.div`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  width: 90vw;

  @media (min-width: 601px) {
    width: 90vw;
  }

  @media (min-width: 993px) {
    width: 90vw;
  }
`;
