import * as React from "react";

const GoogleTagManager = (): JSX.Element => {
  return (
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe 
                      src="https://www.googletagmanager.com/ns.html?id=GTM-MKPCXZW>" 
                      height="0" 
                      width="0"
                      style="display:none;visibility:hidden">
                      </iframe>
            `,
        }}
      />
  );
};
export default GoogleTagManager;
