import { PropsWithChildren } from "react";
import * as React from "react";
import Helmet from "react-helmet";

import { defaultDescription, defaultTitle, siteUrl, social, socialLinks } from "../../data/config";

interface Props extends PropsWithChildren<any> {
  title?: string;
  description?: string;
  location?: string;
  robots?: string;
  canonical?: string;
  image?: string;
}

export const SEO: React.FC<Props> = (props: Props) => {
  const title = props.title !== undefined ? props.title : defaultTitle;
  const description = props.description !== undefined ? props.description : defaultDescription;
  const robots = props.robots !== undefined ? props.robots : "idnex, follow";
  const getCanonical = props.canonical !== undefined ? `/${props.canonical}/` : "/";
  const image = props.image !== undefined ? props.image : "assets/images/thumbnail.png";
  const canonicalComposition = `${siteUrl}/${getCanonical}`;

  const canonical = canonicalComposition.replace(/[\\/]{2,}/g, "/").replace("https:/", "https://");

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta property="og:url" content={`${canonical}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="fb:app_id" content={social.facebook} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={image} />
      <link rel="publisher" href={socialLinks.google} />
      <link rel="canonical" href={`${canonical}`} />
      <title>{title}</title>
      <html lang="es" dir="ltr" />
      <meta name="robots" content={robots} />
    </Helmet>
  );
};
export default SEO;
