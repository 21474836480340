import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 0.85rem;
  background-color: ${(props) => props.theme.darkColor};
  padding: 1em 0em;
  opacity: 0.9;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
`;

export const Europe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #c0c0c0;
  opacity: 0.9;
  img {
    margin-right: 1rem;
    width: 60px;
    height: 39px;
  }
`;

export const Legal = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  a {
    color: ${(props) => props.theme.backgroundLighterColor};
    text-decoration: none;
    padding-left: 1em;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const Details = styled.div`
  padding: 0;
  padding-top: 0.5rem;
  margin: 0 0.5em;
  color: ${(props) => props.theme.backgroundLighterColor};
  ul {
    padding-left: 0.5em;
  }
  li {
    text-align: left;
  } 
    button{
        background-color: transparent;
        border: none;
        outline: none;
        cursor: default;
        padding: 0;
        color: #6a95d8;
        text-decoration: underline;
        text-align: left;
       &:hover {
            color: #a2c6fd;
        }
    }
`;
