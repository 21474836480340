import "styles/index.scss";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "components/CookieConsent/cookie-consent.css";


import React, {useEffect} from "react";

import { Footer } from "../footer";
import * as CookieConsent from "vanilla-cookieconsent";
import Header from "../header";
import GoogleTagManager from "../../CookieConsent/GoogleTagManager";
import {ConfigCookieConsent} from "../../CookieConsent/CookieConsent";

const Layout = ({ children }) => {

    useEffect(() => {
        // window.consentok = (callback) => {
        //   consentListeners.push(callback);
        // };
        CookieConsent.run(ConfigCookieConsent);
        // document.documentElement.classList.add("cc--darkmode");
    }, []);

  return (
    <>
      <GoogleTagManager />
      <div className="square-big" />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
