import styled from "styled-components";
import theme from "styles/style";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  a {
    color: #6a95d8;
    text-decoration: none;
    @media (max-width: 960px) {
      color: $theme.textColor;
    }
  }
  a:hover {
    text-decoration: underline;
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;
