module.exports = {
  defaultTitle: "PCIs RAMÓN Y CAJAL ZARAGOZA",
  titleTrainingDays: "SPANISH ONLINE TRAINING DAY",
  author: "Nagore Barroco, Guillermo Gascon y Alberto Lahuerta",
  siteUrl: "https://pci.iesramonycajalzgz.com/",
  legalName: "IES Ramón y Cajal de Zaragoza",
  defaultDescription:
    "Breaking barriers with the insertion of young people with intellectual disabilities in the working world of the 21st century.",
  socialLinks: {
    facebook: "",
    twitter: "https://twitter.com/IesCajal",
    linkedin: "",
    instagram: "https://www.instagram.com/iesramonycajalzaragoza",
    telegram: "",
    vimeo: "",
    youtube: "",
    tiktok: "",
    medium: "",
    spotify: "",
    podcast: "",
  },
  social: {
    facebook: "@",
    twitter: "@",
    linkedin: "@",
    instagram: "@",
    telegram: "@",
  },
  address: {
    streetAddress: "C/ PIGNATELLI. 102",
    city: "Zaragoza",
    region: "Zaragoza",
    country: "España",
    zipCode: "50004",
  },
  contact: {
    email: "pci.ryc.zgz@gmail.com",
    phone: "976 40 51 80",
  },
  foundingDate: "1996",
};
