import "./menu.scss";

import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import Link from "gatsby-link";
import React from "react";

import { Wrapper } from "./styles";

const NavbarLinks = ({ desktop }) => {
  const { pcis } = useStaticQuery(graphql`
    query topMenu {
      pcis: allPcisJson {
        nodes {
          slug
          name
        }
      }
    }
  `);
  const location = useLocation();
  return (
    <Wrapper desktop={desktop}>
      <nav className={"m-menu"}>
        {pcis.nodes.map((pci) => (
          <Link
            key={pci.slug}
            to={`/${pci.slug}/`}
            className={`a-menu__item ${location.pathname === `/${pci.slug}/` ? "a-menu__item--active" : ""}`}
          >
            {pci.name}
          </Link>
        ))}
        <br />
        <Link
          to="/profesorado/"
          className={`a-menu__item ${location.pathname === `/profesorado/` ? "a-menu__item--active" : ""}`}
        >
          Profesorado
        </Link>
        <a
          href="https://iesryczaragoza.catedu.es/category/erasmus/"
          target={"_blank"}
          rel={"noopener noreferrer"}
          className={`a-menu__item`}
        >
          Erasmus+
        </a>
        <Link
          to="/inserta-xxi/"
          className={`a-menu__item ${location.pathname === `/inserta-xxi/` ? "a-menu__item--active" : ""}`}
        >
          Proyectos Erasmus+
        </Link>
        <Link
          to="/orientacion-profesional/"
          className={`a-menu__item ${location.pathname === `/orientacion-profesional/` ? "a-menu__item--active" : ""}`}
        >
          Orientación profesional
        </Link>
        <Link
          to="/actividades-complementarias/"
          className={`a-menu__item ${
            location.pathname === `/actividades-complementarias/` ? "a-menu__item--active" : ""
          }`}
        >
          Actividades complementarias
        </Link>
      </nav>
    </Wrapper>
  );
};

export default NavbarLinks;
